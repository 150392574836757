$(function () {
    $(".toggle_text").click(function(e){
        e.preventDefault()
        $(this).toggleClass("active");
        $(".wrapper div").toggleClass("active");

        if($(this).hasClass("active")) {
            // var realHeight = $('.wrapper .category-meta-description')[0].scrollHeight
            $('.wrapper div.active').css('max-height', 'fit-content')
            $(this).text("Скрий");
        }
        else{
            $('.wrapper div').css('max-height', '35px')
            $(this).text("Прочети Още");
        }
    });
})
